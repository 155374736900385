import * as React from "react";
import { SEO } from "../components/seo";
import { descriptions } from "../libs/seoContent";
import Layout from "../components/Layout/Layout";
import TestimonialSection from "../components/TestimonialSection/TestimonialSection";
import SubPageTitle from "../components/SubPageTitle/SubPageTitle";
import FiredUpCareer from "../components/FiredUpCareer/FireUpCareer";
import { firedUpCareer } from "../components/SubPageTitle/subPageTitleContent";

const Component = () => {
  return (
    <Layout>
      <SubPageTitle
        content={firedUpCareer}
        bottomSection={<FiredUpCareer content={firedUpCareer} />}
      />
      <TestimonialSection
        pageTitle={"Fired Up Career"}
        className={"testimonial-fired-up-career"}
        bottomRect={true}
      />
    </Layout>
  );
};

export default Component;

export const Head = () => (
  <SEO title="Ready for a Fired-Up career?" description={descriptions.earlyCareers} />
);
